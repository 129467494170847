import { HttpErrorResponse } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonConstants } from '@core/common-constants';
import { ApiEndPoints, DataService } from '@core/services/data.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  APP_TENANT_MGMT_ROUTES,
  ModalFacade,
  SignalRFacade,
  pushCustomEvent,
  setDomainMessageReceived,
  getTenantLogo,
  setTenantLogo,
} from '@ra-state';

import {
  ActionButtonStyles,
  NotificationType,
  DialogService,
  DialogComponent,
} from '@ra-web-tech-ui-toolkit/components';
import { CommonService } from '@rockwell-automation-inc/service';
import { CommandRequestBuilderService } from '@servicesV2/command-request-builder.service';
import {
  AccessRequestEvent,
  CommandRequest,
  EntitlementEvent,
  InvitationEvent,
  TenantEvent,
  UserEvent,
} from '@servicesV2/command-request.service';
import { LoggerService } from '@servicesV2/logger.service';
import * as _ from 'lodash';
import { combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, combineLatestWith, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { openDialogWrapper$ } from '../../v2/shared/utils';
import {
  ControlPageFacade,
  acceptEULASuccess,
  navigateTo,
  setAppIntialRoute,
  setEulaStatus,
  setUserNotFound,
  userLoginComplete,
} from '../control-page';
import { FeatureFlagsFacade, intializeTarget } from '../feature-flags';
import {
  AccessibleTenants,
  anyObjectTo,
  CreateTenantResponse,
  EntitylementTypes,
  IDomainMessage,
  PendingService,
  PendingServiceV2,
  Resource,
  Role,
  ROLES_WITH_CREDIT_LEDGER_ACCESS,
  TenantUtilityTokens,
  UploadLogoPreOperation,
  UserPreferences,
  toUserId,
  AppFlow,
  IGainsightTrialRedeemed,
  Entitlement,
  IGainsightCustomEventName,
  AGGridEntitlement,
} from '../lemans-app.model';
import { openWelcomeDialog } from '../modal/modal.action';
import { displayMessage } from '../snackbar';
import { UserDataFacade } from './user-data-facade';
import {
  acceptInvitation,
  addAccess,
  addSingleAccess,
  addSingleAccessSuccess,
  applyCustomProvisioningEntitlement,
  applyEntitlementCancelled,
  applyRegularEntitlement,
  approveUser,
  approveUserSuccess,
  createInvitations,
  createInvitationsSuccess,
  createSelectedFakeEntitlements,
  createSelectedFakeEntitlementsSuccess,
  createTenant,
  createTenantSuccess,
  customProvisioningCompleted,
  deleteInvitation,
  deleteInvitationSuccess,
  dismissUser,
  dismissUserSuccess,
  emptyAction,
  generateInviteCode,
  generateInviteCodeSuccess,
  getAllTenantResourceRoles,
  getTenantDetails,
  getServicesVisibility,
  getTenantEffectiveRoles,
  getTenantServices,
  getTenantUtilityTokens,
  getUserPreferences,
  getUserPreferencesFailed,
  getUserResources,
  getSuggestedTenantByCode,
  getUserRolesForTenant,
  initializeCustomProvisioning,
  joinTenant,
  joinTenantSuccess,
  leaveCustomProvisioning,
  noLastAccessedTenant,
  removeAccess,
  removeAccessSuccess,
  resendInvitation,
  resendInvitationSuccess,
  setAllTenantResourceRolesSuccess,
  setTenantDetails,
  setTenantId,
  setFavoriteApps,
  setFavoriteAppsSuccess,
  setPreferences,
  setPreferencesSuccess,
  setServicesVisibilitySuccess,
  setTenantEffectiveRoles,
  setTenantServices,
  setTenantUtilityTokens,
  getUserPreferencesSuccess,
  setUserResourcesSuccess,
  setSuggestedTenantByCodeSuccess,
  setUserRolesForTenantSuccess,
  shareFeedback,
  shareFeedbackSuccess,
  switchTenant,
  updateServicesVisibility,
  updateTenant,
  updateTenantNameSuccess,
  updateTenantSuccess,
  uploadOrgLogo,
  uploadOrgLogoSuccess,
  waitForCustomProvisioningComplete,
  updateUserProfile,
  deallocateEntitlement,
  updateEntitlements,
  setCurrentLanguage,
  updatePreferredLanguage,
  redeemTrial,
  getTrialReservationInfo,
  setTrialReservationInfo,
  getTenantUsers,
  setTenantUsers,
  resetTenant,
  removeMulitpleAccess,
  setNoSuggestedTenantByCodeFound,
} from './user-data.action';
import { RouterFacade } from '../router/router.facade';
import { SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class UserDataEffects {
  private readonly DASHBOARD_PATH = '/dashboard';
  private readonly ENTITLEMENT_PATH = '/entitlement';
  private readonly INVITE_USERS_PATH = '/invite-users';
  private readonly EULA_PATH = '/eula';
  openDialogWrapper$ = openDialogWrapper$;
  userID$ = this.userDataFacade.userID$;
  currentTenantId$ = this.userDataFacade.currentTenantId$;
  pendingServices$ = this.userDataFacade.pendingServices$;
  currentCustomProvisioningData$ = this.userDataFacade.currentCustomProvisioningData$;
  currentTenant$ = this.userDataFacade.currentTenant$;
  maintenanceWindow$ = this.featureFlagFacade.maintenanceWindow$;
  queryParams$ = this.routerFacade.queryParams$;

  private isNotNavigatorRole = (role?: Role): boolean => {
    return role !== Role.Navigator;
  };
  getAppFlow(url): AppFlow {
    if (url.includes('/redeem')) {
      return AppFlow.RedeemTrial;
    }
    if (url.includes('invitationId')) {
      return AppFlow.Invitation;
    }
    return AppFlow.Dasbboard;
  }

  loadUserPreferences$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(acceptEULASuccess, userLoginComplete),
      switchMap((payload) => combineLatest([of(payload), this.userID$])),
      switchMap(([payload, userID]) => {
        // TODO: We need to fix this type inference
        return combineLatest([
          of(payload),
          this.dataService.getUser$(String(userID)).pipe(
            catchError((error: unknown) => {
              const httpError = error as HttpErrorResponse;
              this.userDataFacade.getUserPreferencesFailed(httpError);
              return throwError(() => error);
            }),
          ),
          this.maintenanceWindow$,
          of(userID),
        ]);
      }),
      concatLatestFrom(() => this.queryParams$),
      // eslint-disable-next-line sonarjs/cognitive-complexity
      switchMap(([[payload, userPreferences, maintenanceWindow, _userID], routeState]) => {
        const preferences: UserPreferences = {
          accessibleTenants: userPreferences.accessibleTenants,
          businessPartnerId: userPreferences.businessPartnerId,
          company: userPreferences.company,
          email: userPreferences.email,
          eulaAcceptanceRequired: userPreferences.eulaAcceptanceRequired,
          eulaContentUrl: userPreferences.eulaContentUrl,
          eulaVersion: userPreferences.eulaVersion,
          firstName: userPreferences.firstName,
          lastName: userPreferences.lastName,
          location: userPreferences.location,
          name: userPreferences.name,
          preferences: userPreferences.preferences,
          servicesVisibility: [],
          services: [],
        };

        const actions: Action[] = [];
        const appFlow = this.getAppFlow(routeState.url);

        const eulaData = { value: !preferences.eulaAcceptanceRequired };
        if (preferences.eulaAcceptanceRequired && !this.router.url.match(this.EULA_PATH)) {
          this.navigateToEula(routeState);
        } else {
          actions.push(setAppIntialRoute({ payload: { flow: appFlow, snapshot: routeState } }));
        }

        //TODO: remove isInitialState references
        //else if (payload.isInitialState && this.router.url.toLowerCase().startsWith(this.AUTH_CALLBACK_PATH)) {
        //   this.router.navigate([this.DASHBOARD_PATH]);
        // }

        if (payload.type === acceptEULASuccess().type && appFlow === AppFlow.Dasbboard) {
          actions.push(openWelcomeDialog());
        }

        if (!maintenanceWindow.isActive) {
          this.logger.log('POST updateUserProfile');
          actions.push(updateUserProfile());
        }

        let tenantId = '';
        // Ordering of ifs is important to avoid loop...
        if (preferences.preferences?.lastAccessedTenantId) {
          tenantId = preferences.preferences?.lastAccessedTenantId;
          actions.push(setTenantId({ value: tenantId }));
        } else if (preferences.accessibleTenants?.length === 1) {
          tenantId = preferences.accessibleTenants[0].id;
          actions.push(switchTenant({ value: tenantId }));
        } else if (preferences.accessibleTenants?.length === 2) {
          tenantId =
            preferences.accessibleTenants.find((tenant: AccessibleTenants) => tenant.name !== 'Personal Tenant')?.id ||
            '';
          actions.push(switchTenant({ value: tenantId }));
        } else if (appFlow !== AppFlow.Invitation) {
          actions.push(noLastAccessedTenant());
        }

        // TODO: Why this condition?
        // if (
        //   (_.isEmpty(preferences.preferences) ||
        //     preferences.preferences?.lastAccessedTenantId === undefined ||
        //     preferences.preferences?.lastAccessedTenantId === null ||
        //     preferences.preferences?.lastAccessedTenantId === '') && ![AppFlow.Invitation, AppFlow.RedeemTrial].includes(appFlow)
        // ) {
        //   actions.push(noLastAccessedTenant());
        // }
        actions.push(getUserPreferencesSuccess({ value: preferences }));
        actions.push(setEulaStatus(eulaData));
        return actions;
      }),
    );
  });

  updateUserProfile$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(updateUserProfile),
      switchMap(() => {
        return this.dataService.updateUserProfile$();
      }),
      map(() => emptyAction()),
    );
  });

  getUserPreferencesFailed$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getUserPreferencesFailed),
      concatLatestFrom(() => this.queryParams$),
      map(([err, routeState]) => {
        if (err.value.status === 404) {
          this.navigateToEula(routeState);
          return setUserNotFound({ value: true });
        } else {
          return emptyAction();
        }
      }),
    );
  });

  getTenantEffectiveRoles$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTenantEffectiveRoles),
      switchMap(({ payload: tenantId }) => combineLatest([this.userID$, of(tenantId)])),
      switchMap(([userId, tenantId]) => {
        return forkJoin({
          effectiveRoles$: this.dataService.getTenantEffectiveRoles$(tenantId, userId || ''),
          services$: this.dataService.getTenantServices$(String(userId), tenantId),
          tenantId$: of(tenantId),
        });
      }),
      switchMap((response) => {
        const effectiveRoles = response.effectiveRoles$;
        const services = response.services$;
        const tenantRole = effectiveRoles.find((role) => role.resourceType === 'Tenant');
        if (!tenantRole) {
          return throwError(() => new Error('tenant role not found...'));
        }
        const actions: Action[] = [];
        actions.push(setTenantEffectiveRoles({ effectiveRoles: effectiveRoles, tenantRole: tenantRole }));
        actions.push(setTenantServices({ payload: services }));
        if (tenantRole && ROLES_WITH_CREDIT_LEDGER_ACCESS.find((role) => role === tenantRole.role)) {
          actions.push(getTenantUtilityTokens({ value: response.tenantId$ }));
        }
        if (this.isNotNavigatorRole(tenantRole?.role)) {
          actions.push(getTenantDetails({ value: response.tenantId$ }));
        } else {
          actions.push(resetTenant());
        }
        return actions;
      }),
    );
  });

  getTenantResourceRoles$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getAllTenantResourceRoles),
      switchMap((payload) => {
        return this.dataService.getAllTenantResourceRoles$(payload.payload);
      }),
      switchMap((resourceRoles) => {
        return [setAllTenantResourceRolesSuccess({ payload: resourceRoles })];
      }),
    );
  });

  getTenantUsers$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTenantUsers),
      switchMap((payload) => {
        return this.dataService.getTenantUsers$(payload.payload);
      }),
      map((response) => {
        return setTenantUsers({ selectedTenantUsers: response });
      }),
    );
  });

  getUserResourceRoles$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getUserRolesForTenant),
      switchMap((payload) => {
        return this.dataService.getAllTenantResourceRoles$(payload.tenantId, payload.userId);
      }),
      switchMap((resourceRoles) => {
        return [setUserRolesForTenantSuccess({ payload: resourceRoles })];
      }),
    );
  });

  getTenantDetails$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTenantDetails, switchTenant),
      switchMap((tenantId) => {
        return this.navbarCommonService.getTenant$(tenantId.value);
      }),
      switchMap((tenantDetail) => {
        return [setTenantDetails({ payload: tenantDetail }), getTenantLogo({ value: tenantDetail.logoUrl })];
      }),
    );
  });

  getTenantLogo$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTenantLogo),
      switchMap((payload) => {
        const tenantLogoUrl = payload.value;
        return this.dataService.getContent$(tenantLogoUrl);
      }),
      switchMap((tenantlogo) => {
        return [setTenantLogo({ value: tenantlogo })];
      }),
    );
  });

  updateHarnessTargetFlag$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(setTenantDetails),
      concatLatestFrom(() => this.userDataFacade.userData$),
      switchMap(([payload, userData]) => {
        return [
          intializeTarget({
            payload: {
              identifier: toUserId(String(userData.userId)),
              attributes: {
                CurrentTenantId: payload.payload?.id,
                CurrentTenantName: payload.payload?.name,
                Type: 'User',
                Domain: CommonConstants.getEmailDomain(userData.email),
              },
            },
          }),
        ];
      }),
    );
  });

  setTenantId$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(setTenantId),
      switchMap((payload) => {
        const tenantId = payload.value;
        this.navbarCommonService.setCurrentTenantId(tenantId);
        return [getTenantEffectiveRoles({ payload: tenantId })];
      }),
    );
  });

  setPreferences$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(setPreferences),
      concatLatestFrom(() => this.userID$),
      switchMap(([payload, userId]) => {
        return this.dataService.updateUserPreferences$(String(userId), {
          preferences: [{ name: 'lastAccessedTenantId', value: String(payload.tenantId) }],
        });
      }),
      switchMap(() => {
        return [setPreferencesSuccess(), getUserPreferences({ isInitialState: false, isNewUser: false })];
      }),
    );
  });

  udpatePreferredLanguage$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(updatePreferredLanguage),
      concatLatestFrom(() => this.userID$),
      switchMap(([payload, userId]) => {
        return combineLatest([
          this.dataService.updateUserPreferences$(String(userId), {
            preferences: [{ name: 'language', value: payload.preferredLanguage }],
          }),
          of(payload),
        ]);
      }),
      switchMap(([_response, payload]) => {
        return [setCurrentLanguage({ preferredLanguage: payload.preferredLanguage })];
      }),
    );
  });

  setFavoriteApps$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(setFavoriteApps),
      concatLatestFrom(() => this.userID$),
      switchMap(([payload, userId]) => {
        return combineLatest([
          this.dataService.updateUserPreferences$(String(userId), {
            preferences: [
              {
                name: 'favoriteApps',
                value: JSON.stringify(payload.favoriteApps),
              },
            ],
          }),
          of(payload.favoriteApps),
        ]);
      }),
      switchMap(([_response, favoriteApps]) => {
        return [setFavoriteAppsSuccess({ favoriteApps })];
      }),
    );
  });

  createTenant$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(createTenant),
      switchMap((payload) => {
        const commandRequest: CommandRequest = this.commandRequestBuilderService
          .new(
            ApiEndPoints.Tenants,
            'POST',
            (function (countOfEventsToWaitFor: number, eventType: string): (domainMessage: IDomainMessage) => boolean {
              let countOfEventsReceived: number = 0;
              return (domainMessage: IDomainMessage): boolean => {
                if (domainMessage.type === eventType) {
                  countOfEventsReceived += 1;
                }
                return countOfEventsToWaitFor === countOfEventsReceived;
              };
            }(payload.payload.trialServices.length, TenantEvent.ServiceAddedV2)),
          )
          .withIdempotencyKey(payload.idempotencyKey)
          .withBody(payload.payload);

        return combineLatest([
          this.dataService.commandRequest$(commandRequest),
          of(payload.blobImage),
          of(payload.servicesVisibilityRequestBody),
          of(payload.payload.name),
        ]);
      }),
      switchMap(([resp, blobImage, servicesVisibilityRequestBody, organizationName]) => {
        const newTenantId = (resp.response.body as CreateTenantResponse)?.tenantId;
        const actions: Action[] = [];
        this.navbarCommonService.createNewTenant(newTenantId, organizationName);
        actions.push(switchTenant({ value: newTenantId }));
        actions.push(
          createTenantSuccess({
            payload: {
              newTenantId: newTenantId,
              blobImage: blobImage,
              servicesVisibilityRequestBody: servicesVisibilityRequestBody,
            },
          }),
        );
        return actions;
      }),
    );
  });

  createTenantSuccess$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(createTenantSuccess),
      switchMap((payload) => {
        const commandRequest: CommandRequest = this.commandRequestBuilderService
          .new(`${ApiEndPoints.Tenant}services/visibility`, 'POST', TenantEvent.ServiceVisibilityUpdated)
          .withBody(payload.payload.servicesVisibilityRequestBody)
          .withTenantHeader(payload.payload.newTenantId)
          .withWaitOn202Accepted();

        return combineLatest([this.dataService.commandRequest$(commandRequest), of(payload)]);
      }),
      switchMap(([_res, payload]) => {
        const actions: Action[] = [];
        if (payload.payload.blobImage.size > 0) {
          actions.push(
            uploadOrgLogo({
              blobImage: payload.payload.blobImage,
              preOperation: UploadLogoPreOperation.CreateTenant,
              tenantId: payload.payload.newTenantId,
            }),
          );
        }
        return actions;
      }),
    );
  });

  uploadOrgLogo$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(uploadOrgLogo),
      switchMap((payload) => {
        const request$ = this.dataService.getUploadLogoUrl$(String(payload.tenantId));
        return combineLatest([request$, of(payload)]);
      }),
      switchMap(([logoUrl, payload]) => {
        const request$ = this.dataService.uploadOrgLogo$(logoUrl.url, payload.blobImage);
        return combineLatest([request$, of(payload), of(logoUrl.url)]);
      }),
      switchMap(([_res, payload, logoUrl]) => {
        this.navbarCommonService.setTenantLogo(payload.tenantId, logoUrl);
        const actions: Action[] = [];
        actions.push(uploadOrgLogoSuccess());
        this.mapActionsForUploadOrgLogo(actions, payload.preOperation, payload.tenantId);
        return actions;
      }),
    );
  });

  updateTenant$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(updateTenant),
      switchMap((payload) => {
        const commandRequest = this.commandRequestBuilderService
          .new(`${ApiEndPoints.Tenant}`, 'PUT', (domainMessage: IDomainMessage) => {
            return (
              domainMessage.type === TenantEvent.TenantUpdated || domainMessage.type === TenantEvent.TenantNameUpdated
            );
          })
          .withTenantHeader(payload.payload.tenantId)
          .withBody(payload.payload)
          .withWaitOn200Ok();

        return combineLatest([
          this.dataService.commandRequest$(commandRequest),
          of(payload.blobImage),
          of(payload.payload.name),
        ]);
      }),
      switchMap(([domainResponse, blobImage, tenantName]) => {
        const tenantId = domainResponse.message.context.tenantId;
        const actions: Action[] = [];
        if (blobImage) {
          if (domainResponse.message.type === TenantEvent.TenantNameUpdated) {
            actions.push(
              uploadOrgLogo({
                blobImage: blobImage,
                preOperation: UploadLogoPreOperation.UpdateTenantName,
                tenantId: tenantId,
              }),
            );
          } else {
            actions.push(
              uploadOrgLogo({
                blobImage: blobImage,
                preOperation: UploadLogoPreOperation.UpdateTenant,
                tenantId: tenantId,
              }),
            );
          }
        } else {
          actions.push(updateTenantSuccess());
          actions.push(
            displayMessage({
              payload: { message: 'Organization updated successfully.', type: 'Success' },
            }),
          );
          if (domainResponse.message.type === TenantEvent.TenantNameUpdated) {
            actions.push(updateTenantNameSuccess({ value: tenantName }));
            // TODO: is this required?
            //actions.push(getUserPreferences({ isInitialState: false, isNewUser: false })); ???
            this.navbarCommonService.setCurrentTenantIdWithNameUpdate(tenantId.replace(/-/g, ''), tenantName);
          }
          actions.push(getTenantDetails({ value: tenantId }));
        }
        return actions;
      }),
    );
  });

  generateInviteCode$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(generateInviteCode),
      switchMap((payload) => {
        const commandRequest = this.commandRequestBuilderService
          .new(`${ApiEndPoints.Tenant}invitecode`, 'POST', TenantEvent.InviteCodeGenerated)
          .withTenantHeader(payload.tenantId)
          .withWaitOn200Ok();
        return combineLatest([this.dataService.commandRequest$(commandRequest), of(payload)]);
      }),
      switchMap(([_domainResponse, payload]) => {
        return [
          generateInviteCodeSuccess(),
          getTenantDetails({ value: payload.tenantId }),
          displayMessage({
            payload: {
              message: 'You have created a new invite code successfully!',
              type: 'Success',
            },
          }),
        ];
      }),
    );
  });

  joinTenant$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(joinTenant),
      switchMap((payload) => {
        const commandRequest = this.commandRequestBuilderService
          .new(`${ApiEndPoints.AccessRequest}`, 'POST', AccessRequestEvent.AccessRequestCreated)
          .withBody(payload.payload);
        return combineLatest([this.dataService.commandRequest$(commandRequest), of(payload)]);
      }),
      switchMap((res) => {
        if (res && res.length && res[1] && res[1].isRedirectToDashboard !== false) {
          this.router.navigate([this.DASHBOARD_PATH]);
        }
        return [
          joinTenantSuccess(),
          displayMessage({
            payload: {
              message:
                'Request Sent Successfully! You will be notified once the owner of the organization approves your request',
              type: 'Success',
            },
          }),
        ];
      }),
    );
  });

  switchTenant$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(switchTenant),
      switchMap((payload) => {
        const dispatchActions: Action[] = [];
        dispatchActions.push(setPreferences({ tenantId: payload.value }));
        dispatchActions.push(setTenantId({ value: payload.value }));
        if (APP_TENANT_MGMT_ROUTES.includes(this.router.url.toLowerCase())) {
          dispatchActions.push(navigateTo({ path: this.DASHBOARD_PATH }));
        }
        return dispatchActions;
      }),
    );
  });

  createInvitations$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(createInvitations),
      switchMap((invitations) => {
        const requests$: Observable<any>[] = [];
        const list = invitations.payload;
        list.forEach((item) => {
          const commandRequest = this.commandRequestBuilderService
            .new(ApiEndPoints.Invitations, 'POST', InvitationEvent.InvitationCreated)
            .withTenantHeader(item.tenantId)
            .withBody(item)
            .withErrorHandler((error: unknown) => {
              const httpError = error as HttpErrorResponse;
              if (httpError.error?.errorCode === 'InvitationAlreadyExists') {
                return of({ errorResponse: error, email: item.toEmail });
              }
              return throwError(() => error);
            });

          requests$.push(this.dataService.commandRequest$(commandRequest));
        });
        return forkJoin(requests$);
      }),
      mergeMap((responses) => {
        const invitationAlreadyExistsErrors = responses.filter(
          (response) =>
            response.errorResponse &&
            response.errorResponse.error &&
            response.errorResponse.error.errorCode === 'InvitationAlreadyExists',
        );
        const noErrors = invitationAlreadyExistsErrors.length === 0;
        if (noErrors) {
          return [
            createInvitationsSuccess(),
            displayMessage({
              payload: {
                message: 'Invitation Sent Successfully! You will be notified once users join the Organization!',
                type: 'Success',
              },
            }),
          ];
        }

        const partialErrors = invitationAlreadyExistsErrors.length < responses.length;
        if (partialErrors) {
          const errorEmails = invitationAlreadyExistsErrors.map((error) => error.email).join(', ');
          return [
            createInvitationsSuccess(),
            this.dialogSvc.openDialog({
              title: 'Invite Users',
              message: `Skipped sending invitation to following user(s) as active invitation exists: ${errorEmails}`,
              messageType: NotificationType.Warning,
              buttons: [
                {
                  label: 'OK',
                  buttonStyle: ActionButtonStyles.Main,
                  shouldAutofocus: true,
                },
              ],
            }),
          ];
        }
        return [
          displayMessage({
            payload: {
              message:
                invitationAlreadyExistsErrors.length > 1
                  ? 'Skipped sending invitations as active invitations already exists.'
                  : 'Skipped sending invitation as active invitation already exists.',
              type: 'Error',
            },
          }),
        ];
      }),
    );
  });

  resendInvitation$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(resendInvitation),
      switchMap((value) => {
        const url = `/api/invitations/${value.invitationId}/reissue`;
        const commandRequest = this.commandRequestBuilderService
          .new(url, 'POST', InvitationEvent.InvitationReissuedV2)
          .withTenantHeader(value.tenantId)
          .withWaitOn200Ok();

        return this.dataService.commandRequest$(commandRequest);
      }),
      mergeMap(() => {
        return [
          resendInvitationSuccess(),
          displayMessage({
            payload: {
              message: 'Invitation resent successfully!. You will be notified once the user joins the organization.',
              type: 'Success',
            },
          }),
        ];
      }),
    );
  });

  deleteInvitation$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(deleteInvitation),
      switchMap((value) => {
        const url = `/api/invitations/${value.invitationId}`;
        const commandRequest = this.commandRequestBuilderService
          .new(url, 'DELETE', InvitationEvent.InvitationFinalized)
          .withTenantHeader(value.tenantId)
          .withWaitOn200Ok();
        return this.dataService.commandRequest$(commandRequest);
      }),
      mergeMap(() => {
        return [
          deleteInvitationSuccess({ value: true }),
          displayMessage({
            payload: {
              message: 'Invitation has been deleted/canceled successfully.',
              type: 'Info',
            },
          }),
        ];
      }),
    );
  });

  shareFeedback$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(shareFeedback),
      switchMap((payload) => {
        return this.dataService.shareFeedback$(payload.payload);
      }),
      map(() => {
        return shareFeedbackSuccess();
      }),
    );
  });

  getSuggestedTenantByCode$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getSuggestedTenantByCode),
      filter((value) => value.inviteCode !== undefined),
      switchMap((value) => {
        return this.dataService.getSuggestedTenantByCode$(String(value.inviteCode));
      }),
      map((suggestedTenants) => {
        if (!suggestedTenants.length) {
          return setNoSuggestedTenantByCodeFound({ payload: true });
        } else {
          return setSuggestedTenantByCodeSuccess({ payload: suggestedTenants[0] });
        }
      }),
    );
  });

  getUserResources$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getUserResources),
      filter((value) => value.value !== undefined),
      switchMap((value) => {
        return this.dataService.getUserResources$(String(value.value));
      }),
      switchMap((resources: Resource[]) => {
        return [setUserResourcesSuccess({ payload: resources })];
      }),
    );
  });

  createSelectedFakeEntitlements$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(createSelectedFakeEntitlements),
      switchMap((value) => {
        const payload = value.payload;
        const commandRequest = this.commandRequestBuilderService
          .new(
            '/api/users/entitlements/update',
            'POST',
            (msg) => msg.type === TenantEvent.EntitlementAddedV2 || msg.type === TenantEvent.TokenEntitlementAllocated,
          )
          .withTenantHeader(value.tenantId)
          .withBody(payload)
          .withWaitOn200Ok();

        return this.dataService.commandRequest$(commandRequest);
      }),
      switchMap(() => {
        return [createSelectedFakeEntitlementsSuccess({ value: true })];
      }),
    );
  });

  applyRegularEntitlement$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(applyRegularEntitlement),
      switchMap((value) => {
        const url = `/api/users/${value.userId}/entitlements/${value.entitlementData.entitlement.id}?quantity=${value.quantity}`;
        const commandRequest = this.commandRequestBuilderService
          .new(
            url,
            'POST',
            (msg) => msg.type === TenantEvent.EntitlementAddedV2 || msg.type === TenantEvent.TokenEntitlementAllocated,
          )
          .withTenantHeader(value.tenantId)
          .withCustomTimer('7000')
          .withWaitOn202Accepted()
          .withCustomErrorMessage([
            {
              errorCode:
                'EntitlementError-Platform/Trial/NewTrial/Additive entitlement needs to applied for activating the service',
              message: `Cannot allocate this entitlement when there is no active ${(value.entitlementData?.entitlement as AGGridEntitlement)?.productFamily} product entitlement allocated.`,
            },
          ]);

        return combineLatest([this.dataService.commandRequest$(commandRequest), of(value)]);
      }),
      switchMap(([, value]) => {
        const { attributes, available, name } = value.entitlementData.entitlement;
        let messageBody = '';
        let customBtn;
        if (attributes.combineType !== 'utilityToken') {
          messageBody = `${name} allocated successfully to ${value.organizationName}.`;
          customBtn = {
            label: 'Invite Users',
            navigateTo: '/invite-users',
          };
        } else {
          messageBody = `${value.quantity === available ? 'Full' : value.quantity} ${name} allocated successfully to ${
            value.organizationName
          }.`;
        }

        return [
          updateEntitlements({ value: true }),
          displayMessage({
            payload: {
              message: messageBody,
              type: 'Success',
              customBtn: customBtn,
            },
          }),
          getTenantEffectiveRoles({ payload: String(value.tenantId) }),
        ];
      }),
    );
  });

  applyCustomProvisioningEntitlement$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(applyCustomProvisioningEntitlement),
      switchMap((value) => {
        const url = `/api/users/${value.userId}/entitlements/${value.entitlementData.entitlement.id}?quantity=${value.quantity}`;
        const commandRequest = this.commandRequestBuilderService
          .new(url, 'POST', (msg) => msg.type === TenantEvent.ServiceAdditionStarted)
          .withTenantHeader(value.tenantId)
          .withWaitOn202Accepted();
        return combineLatest([this.dataService.commandRequest$(commandRequest), of(value)]);
      }),
      switchMap(([apiResponse, value]) => {
        const data = anyObjectTo<PendingServiceV2>(apiResponse.message.data);
        const serviceId = data.service.serviceId;
        return [
          initializeCustomProvisioning({
            payload: {
              app: value.entitlementData.app,
              tenantId: value.tenantId,
              entitlement: value.entitlementData.entitlement,
              serviceId: serviceId,
              userId: value.userId,
            },
          }),
        ];
      }),
    );
  });

  openIframe$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(initializeCustomProvisioning),
      switchMap((currentPSD) => {
        this.router.navigate(['/entitlement/hub'], {
          queryParams: {
            tenantId: currentPSD.payload.tenantId,
            serviceId: currentPSD.payload.serviceId,
          },
        });
        return [waitForCustomProvisioningComplete({ payload: currentPSD.payload })];
      }),
    );
  });

  waitForCustomProvisioningComplete$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(setDomainMessageReceived),
      filter((payload) => payload.payload.type === TenantEvent.ServiceAddedV2),
      mergeMap((payload) => combineLatest([of(payload), this.currentCustomProvisioningData$])),
      switchMap(([payload, currentPSD]) => {
        const actions: Action[] = [];
        const data = anyObjectTo<PendingService>(payload.payload.data);
        if (data.serviceId !== currentPSD?.serviceId) {
          actions.push(emptyAction());
          return actions;
        }
        actions.push(getTenantEffectiveRoles({ payload: String(currentPSD.tenantId) }));
        actions.push(customProvisioningCompleted());
        this.router.navigate([this.INVITE_USERS_PATH]);
        return actions;
      }),
    );
  });

  waitForCustomProvisioningCancelation$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(setDomainMessageReceived),
      filter((payload) => payload.payload.type === TenantEvent.ServiceAdditionCancelled),
      mergeMap((payload) => combineLatest([of(payload), this.currentCustomProvisioningData$])),

      //delay(1500),
      // NOTE - fixed the event type when client cancels CSP flow and we have to run compensating action
      // this delay was probably a workaround - https://github.com/Rockwell-Automation-Inc/LeMans-Home-UI/pull/1206 that
      // had the wrong event; now since we changed the event type, removing the delay.

      switchMap(([payload, currentPSD]) => {
        const actions: Action[] = [];
        //TODO: Remove - now that we have parsed json from the server
        const data: PendingServiceV2 = payload.payload.data as unknown as PendingServiceV2;

        if (data.service.serviceId !== currentPSD?.serviceId) {
          actions.push(emptyAction());
          return actions;
        }

        actions.push(getTenantEffectiveRoles({ payload: String(currentPSD.tenantId) }));
        actions.push(
          getTenantServices({
            payload: {
              tenantId: String(currentPSD.tenantId),
              userId: String(currentPSD.userId),
            },
          }),
        );
        actions.push(applyEntitlementCancelled());
        actions.push(leaveCustomProvisioning());

        this.router.navigate([this.ENTITLEMENT_PATH]);
        return actions;
      }),
    );
  });

  deallocateEntitlement$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(deallocateEntitlement),
      switchMap((payload) => {
        const entitlemenId = payload.entitlement.id;
        const url = `/api/tenant/entitlements/${entitlemenId}/deallocate`;
        const commandRequest = this.commandRequestBuilderService
          .new(url, 'POST', (msg) => msg.type === EntitlementEvent.EntitlementDeallocated)
          .withBody({
            code: entitlemenId,
            allocationTimestamp: payload.entitlement.timestamp,
            allocated: payload.entitlement.allocated,
            quantity: payload.quantity === 'Full' ? null : payload.quantity,
          })
          .withTenantHeader(payload.tenantId)
          .withWaitOn202Accepted()
          .withCustomErrorMessage([
            {
              errorCode: 403,
              message: 'Insufficient Permissions. Please contact organization admin to deallocate the entitlement',
            },
          ]);

        return combineLatest([this.dataService.commandRequest$(commandRequest), of(payload)]);
      }),
      concatLatestFrom(() => this.userID$),
      switchMap(([[, payload], userId]) => {
        return [
          getTenantEffectiveRoles({ payload: String(payload.tenantId) }),
          updateEntitlements({ value: true }),
          getTenantServices({
            payload: {
              tenantId: String(payload.tenantId),
              userId: String(userId),
            },
          }),
          displayMessage({
            payload: {
              message: `${payload.quantity} ${payload.entitlement['detailServiceDataHeaders'].header} removed successfully from ${payload.organizationName}.`,
              type: 'Success',
            },
          }),
        ];
      }),
    );
  });

  addAccess$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(addAccess),
      mergeMap((addAccessList) => {
        const dispatchAccessList: Action[] = [];
        const list = addAccessList.payload;
        list.forEach((item) =>
          dispatchAccessList.push(
            addSingleAccess({ flow: addAccessList.flow, tenantId: addAccessList.tenantId, value: item }),
          ),
        );
        return dispatchAccessList;
      }),
    );
  });

  addSingleAccess$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(addSingleAccess),
      mergeMap((tenantAccess) => {
        const tenantAccessData = tenantAccess.value;
        const bodyRequest = {
          resourceId: tenantAccessData.resourceId,
          role: tenantAccessData.role,
        };
        const commandRequest = this.commandRequestBuilderService
          .new(ApiEndPoints.Users + tenantAccess.value.userId + '/roles', 'POST', UserEvent.UserRoleAssigned)
          .withTenantHeader(tenantAccess.tenantId)
          .withBody(bodyRequest)
          .withWaitOn200Ok();
        return combineLatest([of(tenantAccess.flow), this.dataService.commandRequest$(commandRequest)]);
      }),
      mergeMap(([response]) => {
        let message = '';
        if (response === 'add') {
          message = CommonConstants.responseMessageIconColorMap['add-access'].message;
        } else {
          message = CommonConstants.responseMessageIconColorMap['edit-access'].message;
        }
        if (response !== 'approve') {
          this.router.navigate(['/access-mngmt']);
        }
        return [
          addSingleAccessSuccess(),
          displayMessage({
            payload: {
              message: message,
              type: 'Success',
            },
          }),
        ];
      }),
    );
  });

  approveUser$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(approveUser),
      switchMap((tenantAccess) => {
        const dataMapped = tenantAccess.payload[0];

        const commandRequest = this.commandRequestBuilderService
          .new(ApiEndPoints.AccessRequest + '/' + tenantAccess.id + '/approve', 'POST', AccessRequestEvent.Approved)
          .withTenantHeader(dataMapped.resourceId)
          .withBody({ role: dataMapped.role })
          .withWaitOn200Ok();

        return this.dataService.commandRequest$(commandRequest);
      }),
      switchMap((_response) => {
        return [
          approveUserSuccess({ value: true }),
          displayMessage({
            payload: {
              message: 'User has been approved.',
              type: 'Success',
            },
          }),
        ];
      }),
    );
  });

  removeMulitpleAccess$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(removeMulitpleAccess),
      mergeMap((removeAccessList) => {
        const dispatchRemoveAccessList: Action[] = [];
        const list = removeAccessList.payload;
        list.forEach((item) => dispatchRemoveAccessList.push(removeAccess({ payload: item })));
        return dispatchRemoveAccessList;
      }),
    );
  });

  deleteAccess$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(removeAccess),
      mergeMap((removeData) => {
        const data = removeData.payload;
        const commandRequest = this.commandRequestBuilderService
          .new(
            ApiEndPoints.Users + data.userId + '/roles/?resid=' + data.resourceId,
            'DELETE',
            UserEvent.UserRoleRevoked,
          )
          .withTenantHeader(data.tenantId)
          .withWaitOn200Ok();

        return this.dataService.commandRequest$(commandRequest);
      }),
      mergeMap(() => {
        this.router.navigate(['/access-mngmt']);
        return [
          removeAccessSuccess({ value: true }),
          displayMessage({
            payload: {
              message: 'Access has been removed successfully.',
              type: 'Info',
            },
          }),
        ];
      }),
    );
  });
  dismissUser$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(dismissUser),
      switchMap((removeData) => {
        const commandRequest = this.commandRequestBuilderService
          .new(ApiEndPoints.AccessRequest + '/' + removeData.value, 'DELETE', 'Deleted')
          .withTenantHeader(removeData.tenantId)
          .withWaitOn200Ok();
        return this.dataService.commandRequest$(commandRequest);
      }),
      switchMap(() => [
        dismissUserSuccess({ value: true }),
        displayMessage({
          payload: {
            message: 'Access request dismissed.',
            type: 'Info',
          },
        }),
      ]),
    );
  });

  acceptInvitation$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(acceptInvitation),
      combineLatestWith(this.signalRFacade.ensureIsConnected$),
      switchMap(([value]) => {
        const url = `${ApiEndPoints.Invitations}${value.invitationId}/accept`;
        const commandRequest = this.commandRequestBuilderService
          .new(
            url,
            'POST',
            (ev) => ev.type === UserEvent.UserRoleAssigned || ev.type === InvitationEvent.UserRoleUpdateErroredV2,
          )
          .withWaitOn200Ok();
        return combineLatest([
          this.dataService.commandRequest$(commandRequest).pipe(
            catchError((error: unknown) => {
              this.router.navigate([], {
                queryParams: {
                  invitationId: null,
                },
                queryParamsHandling: 'merge',
              });
              return throwError(() => error);
            }),
          ),
          of(value.invitationId),
        ]);
      }),
      concatLatestFrom(() => this.userDataFacade.accessibleTenants$),
      mergeMap(([[response, invitationId], accessibleTenants]) => {
        this.logger.log('Invitation state update msg recd', response);
        let tenantId: string | null = invitationId.split('-')[1];
        let dialog;
        if (response.message.type === UserEvent.UserRoleAssigned) {
          this.navbarCommonService.refreshOrganizations();
          dialog = this.dialogSvc.openDialog({
            title: 'Invitation Accepted Successfully!',
            message: 'You will be switched to the new organization.',
            messageType: NotificationType.Success,
            buttons: [
              {
                label: 'OK',
                buttonStyle: ActionButtonStyles.Main,
                shouldAutofocus: true,
              },
            ],
          });
        } else if (response.message.type === InvitationEvent.UserRoleUpdateErroredV2) {
          let msg = 'Accepting the invitation failed due to an internal error';
          // TODO: handle this before making an api call
          if (response.message.data.message === 'MemberCannotAcceptInvite') {
            const tenantName = accessibleTenants.filter((tenant) => tenant.id === tenantId)[0]?.name;
            msg = `This invitation cannot be accepted as you are already a member of ${tenantName}. For additional access, please contact your administrator.`;
          }
          dialog = this.dialogSvc.openDialog({
            title: `Organization Invitation cannot be accepted`,
            message: msg,
            messageType: NotificationType.Error,
            buttons: [
              {
                label: 'OK',
                buttonStyle: ActionButtonStyles.Main,
                shouldAutofocus: true,
              },
            ],
          });
          tenantId = null;
        } else {
          tenantId = null;
        }
        const dialogRef = dialog as MatDialogRef<DialogComponent, any>;
        return combineLatest([dialogRef.componentInstance.onClick, of(tenantId)]);
      }),
      mergeMap(([_response, tenantId]) => {
        //remove invitationid from url query param
        this.router.navigate([], {
          queryParams: {
            invitationId: null,
          },
          queryParamsHandling: 'merge',
        });
        if (tenantId === null) {
          return [emptyAction()];
        }
        return [switchTenant({ value: String(tenantId) })];
      }),
    );
  });

  navigateToEula(routeSnapshot: SerializedRouterStateSnapshot): void {
    this.router.navigate(['eula'], { queryParams: { postEula: routeSnapshot.url || '/dashboard' } });
  }

  mapActionsForUploadOrgLogo(actions: Action[], preOperation: UploadLogoPreOperation, tenantId: string): Action[] {
    const displayMessageAction = displayMessage({
      payload: {
        message: 'Organization updated successfully.',
        type: 'Success',
      },
    });

    switch (preOperation) {
      case UploadLogoPreOperation.CreateTenant: {
        actions.push(switchTenant({ value: tenantId }));
        break;
      }
      case UploadLogoPreOperation.UpdateTenantName: {
        actions.push(displayMessageAction);
        actions.push(getUserPreferences({ isInitialState: false, isNewUser: false }));
        break;
      }
      case UploadLogoPreOperation.UpdateTenant:
      case UploadLogoPreOperation.None:
      default: {
        actions.push(displayMessageAction);
        actions.push(getTenantDetails({ value: tenantId }));
        break;
      }
    }
    return actions;
  }

  getServicesVisibility$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getServicesVisibility),
      switchMap((tenantId) => {
        return this.dataService.getServicesVisibility$(tenantId.value);
      }),
      concatLatestFrom(() => this.controlPageFacade.getApps$),
      map(([servicesVisibility, enabledApps]) => {
        const filteredVisibility = servicesVisibility.filter((service) => {
          return Boolean(enabledApps.find((app) => app.appId === service.serviceKind));
        });
        return setServicesVisibilitySuccess({ payload: filteredVisibility });
      }),
    );
  });

  updateServicesVisibility$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(updateServicesVisibility),
      switchMap((servicesVisibilityData) => {
        const commandRequest = this.commandRequestBuilderService
          .new(`${ApiEndPoints.Tenant}services/visibility`, 'POST', 'ServiceVisibilityUpdated')
          .withBody(servicesVisibilityData.payload)
          .withTenantHeader(servicesVisibilityData.tenantId)
          .withWaitOn202Accepted();

        return combineLatest([
          this.dataService.commandRequest$(commandRequest),
          of(servicesVisibilityData),
          this.userID$,
        ]);
      }),
      switchMap(([_response, servicesVisibilityData, userId]) => {
        return [
          getServicesVisibility({ value: servicesVisibilityData.tenantId }),
          getTenantServices({
            payload: {
              tenantId: String(servicesVisibilityData.tenantId),
              userId: String(userId),
            },
          }),
          displayMessage({
            payload: {
              message: 'Applications visibility updated successfully.',
              type: 'Success',
            },
          }),
        ];
      }),
    );
  });

  getTenantServices$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTenantServices),
      switchMap((payload) => {
        const userTenant = payload.payload;
        return this.dataService.getTenantServices$(userTenant.userId, userTenant.tenantId);
      }),
      switchMap((services) => {
        return [setTenantServices({ payload: services })];
      }),
    );
  });

  getTenantUtilityTokens$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTenantUtilityTokens),
      switchMap((tenantId) => {
        return this.dataService.getTenantUtilityTokens$(tenantId.value);
      }),
      switchMap((tenantUtilityTokens: TenantUtilityTokens) => {
        //TODO: remove this piece of code when the backend provides the correct combineType
        tenantUtilityTokens.tokenEntitlements.forEach((entitlement) => {
          if (_.isEmpty(entitlement.attributes)) {
            entitlement.attributes = {
              combineType: EntitylementTypes.utilityToken,
            };
          }
        });
        tenantUtilityTokens.disabledTokenEntitlements.forEach((disabledEntitlement) => {
          if (_.isEmpty(disabledEntitlement.attributes)) {
            disabledEntitlement.attributes = {
              combineType: EntitylementTypes.utilityToken,
            };
          }
        });
        return [setTenantUtilityTokens({ payload: tenantUtilityTokens })];
      }),
    );
  });

  getTrialReservationInfo$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTrialReservationInfo),
      switchMap((request) => {
        return this.dataService.getTrialReservationInfo$(request.payload);
      }),
      switchMap((trialReservation) => {
        let title = 'Invalid Trial Link';
        let message = 'Please contact your sales representative for a new link. We apologize for the inconvenience.';

        if (trialReservation.status === 'redeemed') {
          title = 'Trial already redeemed';
          message =
            'This trial link has already been used. Please check your account entitlements. If you still have questions, reach out to your sales representative.';
        } else if (trialReservation.status === 'expired') {
          message =
            'Looks like your link has expired. Please contact your sales representative for a new link. We apologize for the inconvenience.';
        }
        const config = {
          title: title,
          message: message,
          buttons: [{ label: 'Close', buttonStyle: ActionButtonStyles.Main }],
          showCloseIconButton: true,
        };
        if (trialReservation.status !== 'active') {
          this.modalFacade.openWebTechDialog(config, NotificationType.Error);
          this.router.navigate([this.DASHBOARD_PATH]);
        }
        return [setTrialReservationInfo({ payload: trialReservation })];
      }),
    );
  });

  redeemTrail$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(redeemTrial),
      combineLatestWith(this.signalRFacade.ensureIsConnected$),
      switchMap(([payload]) => {
        const url = `${ApiEndPoints.Trial}/${payload.payload.campaignId}/reservations/${payload.payload.trialReservationId}/redeem`;
        const commandRequest = this.commandRequestBuilderService
          .new(url, 'PUT', EntitlementEvent.EntitlementAddedV2)
          .withWaitOn200Ok()
          .withErrorHandler((error: unknown) => {
            const httpError = error as HttpErrorResponse;
            let title = 'Invalid Trial Link';
            let message =
              'Please contact your sales representative for a new link. We apologize for the inconvenience.';
            if (httpError.error?.errorCode === 'TrialAlreadyRedeemed') {
              title = 'Trial already redeemed';
              message =
                'This trial link has already been used. Please check your account entitlements. If you still have questions, reach out to your sales representative.';
            }
            const config = {
              title: title,
              message: message,
              buttons: [{ label: 'Close', buttonStyle: ActionButtonStyles.Main }],
              showCloseIconButton: true,
            };
            this.modalFacade.openWebTechDialog(config, NotificationType.Error);
            this.router.navigate([this.DASHBOARD_PATH]);
            return throwError(() => error);
          });
        return combineLatest([this.dataService.commandRequest$(commandRequest), of(payload)]);
      }),
      concatLatestFrom(() => this.currentTenant$),
      switchMap(([[response, payload], tenant]) => {
        const trialReservation = payload.payload;
        //TODO: remove this logic to parse event data once API support available
        const entInfo = response.message.data.entitlement as Entitlement;
        const trialRedeemed: IGainsightTrialRedeemed = {
          campaignId: trialReservation.campaignId,
          durationInDays: trialReservation.trialReservationDetails.durationInDays,
          status: trialReservation.trialReservationDetails.status,
          trialReservationId: trialReservation.trialReservationId,
          expiresOn: trialReservation.trialReservationDetails.expiresOn,
          catalogNumber: entInfo.catalogNumber,
          contractNumber: entInfo.contractNumber,
          quantity: entInfo.quantity,
          organizationId: String(tenant?.id),
          organizationName: String(tenant?.name),
        };
        this.router.navigate([this.ENTITLEMENT_PATH]);
        return [
          displayMessage({
            payload: {
              message: 'Trial redeemed successfully! Trial entitlement has been added to your account',
              type: 'Success',
            },
          }),
          pushCustomEvent({
            gainsightCustomEvent: {
              eventName: IGainsightCustomEventName.TrialRedeemed,
              eventPayload: trialRedeemed,
            },
          }),
        ];
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private userDataFacade: UserDataFacade,
    private controlPageFacade: ControlPageFacade,
    private router: Router,
    private dataService: DataService,
    private commandRequestBuilderService: CommandRequestBuilderService,
    private logger: LoggerService,
    private navbarCommonService: CommonService,
    private dialogSvc: DialogService,
    private featureFlagFacade: FeatureFlagsFacade,
    private modalFacade: ModalFacade,
    private routerFacade: RouterFacade,
    private signalRFacade: SignalRFacade,
  ) {}
}
